<template>
<div>
    <div class="wrap">
        <div class="con_top">
            <h2><span>■</span> B2C 수납등록</h2>
        </div>
        <div class="con">
            <div class="con_table">
                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                    <tbody>
                        <tr>
                            <th style="width:111px;">고객명 <span class="red">*</span></th>
                            <td class="left">
                                <div id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                    <div class="search">
                                        <input type="text" v-model="name" class="ml-20" style="width:400px;" id="center" name="center" @input="watchEvent($event, 'client')">
                                        <ul class="c" tabindex="0" v-bind:class="{ show: isAutoClient }" v-on:mouseover="removeValue">
                                            <li tabindex="-1" v-for="(el, index) in filterClientList" v-on:click="changeValue(el.name, 'client', el.idx)" v-on:keyup.enter="selectValue('enter', el.name)" :key="index">
                                                <span>{{ el.nickName }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <th>수납일자 <span class="red">*</span></th>
                            <td class="left">
                                <crm-datepicker class="ml-20" v-model="purDate" :parentDate="purDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            </td>
                        </tr>
                        <tr>
                            <th style="width:111px;">수납금액 <span class="red">*</span></th>
                            <td class="left">
                                <select v-model="status" class="w-120px ml-20">
                                    <option value="수납">수납</option>
                                    <option value="환불">환불</option>
                                    <option value="미수금">미수금</option>
                                </select>
                                <input type="text" v-model="purPrice" class="ml-20" style="width:200px;" value=""> 원<br>
                            </td>
                            <th>수납방법</th>
                            <td class="left">
                                <select v-model="purPayWay" class="w-120px ml-20">
                                    <option value="현금">현금</option>
                                    <option value="카드">카드</option>
                                    <option value="계좌이체">계좌이체</option>
                                    <option value="상품권">상품권</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                    <tbody>
                        <tr>
                            <th style="width: 111px;">메모</th>
                            <td class="left">
                                <textarea v-model="memo" type="text" class="ml-20 w-98per h-100px" id="introduce" name="introduce" value="" />
                                </td>
                        </tr>
                        </tbody>
                </table>

                <div class="btns3 mgB80">
                    <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                    <a class="btn_cancle pointer" @click="register()">등록</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
import {
    EmptyCheck
} from '@/utils/validation.js'
export default {
    data: () => ({
        idxCrmClient: -1,
        name: '', // 고객명
        purDate: '', // 상담날짜
        purPrice: '', // 수납금액
        refundPrice: '', // 환불금액
        purPayWay: '현금', // 카드,계좌이체,상품권
        status: '수납',
        clientList: [],
        memo: '',
        idxCrmCompany: -1,

        isAutoClient: false, // 유저 자동완성 on/off
        autoSearchClient: '', // 유저 자동완성 검색
        clientList: [], // 유저 리스트 (자동완성)
        type: 'B2C',
        btnDisabled:false, //등록버튼 활성화/비활성화 여부
    }),

    computed: {
        filterClientList() {
            const str = this.autoSearchClient
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoClient = true
                var filterNames = []
                filterNames = this.clientList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoClient = false
                }
            } else {
                this.isAutoClient = false
            }
        }
    },

    mounted() {
        this.purDate = this.formatDate(new Date())
        var oParams = GetUrlParams()
        this.idxCrmClient = parseInt(oParams.idxCrmClient)
        this.getClientList()
    },

    methods: {

        // 센터 자동완성 입력 감지
        watchEvent(e, type) {
            if (type === 'counselor') {
                this.autoSearchCounselor = e.target.value
            } else {
                this.autoSearchClient = e.target.value
            }
        },

        // 자동완성 끄기
        removeValue() {
            if (document.querySelector('.r').classList.contains('key')) {
                document.querySelector('.r').classList.remove('key');
                document.querySelector('.r li.sel').classList.remove('sel');
            }
            if (document.querySelector('.c').classList.contains('key')) {
                document.querySelector('.c').classList.remove('key');
                document.querySelector('.c li.sel').classList.remove('sel');
            }
        },

        // 자동완성 선택한 경우 센터명 변경
        changeValue(str, type, idx, centerIdx, centerName) {
            if (type === 'counselor') {
                console.log('idx', idx)
                console.log('centerIdx', centerIdx)
                console.log('centerName', centerName)
                this.isAutoCounselor = false
                this.staffName = str
                this.idxCrmStaff = idx
            } else {
                console.log('idx', idx)
                this.isAutoClient = false
                this.name = str
                this.idxCrmClient = idx
            }
        },

        // 자동완성 켜기 및 선택
        selectValue(keycode, str, idx, centerIdx, centerName) {
            if (this.isAutoCounselor) {
                const hasClass = document.querySelector('.r').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        console.log('여기탐')
                        const thisEl = document.querySelectorAll('.r li')[0];
                        document.querySelector('.r').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.r li:last-child');
                        const thisEl = document.querySelector('.r li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.r li')[0];
                    const thisEl = document.querySelector('.r li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'counselor', idx, centerIdx, centerName);
                }
            }

            if (this.isAutoClient) {
                const hasClass = document.querySelector('.c').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.c li')[0];
                        document.querySelector('.c').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.c li:last-child');
                        const thisEl = document.querySelector('.c li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.c li')[0];
                    const thisEl = document.querySelector('.c li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'client', idx);
                }
            }
        },

        // 고객 전체목록 호출
        getClientList() {

            let params = {
                type: this.type
            }
            this.axios.get('/api/v1/client/all', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.clientList = res.data.client
                        
                        if(this.idxCrmClient !== -1) {
                            for(var i=0; i<res.data.client.length;i++) {
                                if(res.data.client[i].idx === this.idxCrmClient) {
                                    this.name =  res.data.client[i].name
                                }
                            }
                        }
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 이전 페이지로 돌아가기
        cancel() {
            //this.$router.push('/origin/customer_acceptance_b2c')
            history.back()
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        // 등록하기
        register() {

            let flag = false

            // 고객 자동완성 체크 부분
            if (!EmptyCheck(this.name, '고객을')) {
                return false
            } else {
                for (var client of this.clientList) {
                    if (client.name === this.name && client.idx === Number(this.idxCrmClient)) {
                        flag = true
                        break;
                    }
                }
            }

            if (!flag) {
                alert('고객명을 다시 확인해주세요')
                return false
            }

            if (this.idxCrmClient === -1) {
                alert('존재하지 않는 고객입니다.')
                return false
            }

            if (!EmptyCheck(this.purPrice + '', '수납금액을')) {
                return false
            }

            if (!EmptyCheck(this.formatDate(this.purDate), '수납일자를')) {
                return false
            }

            var vo = {
                purDate: this.formatDate(this.purDate),
                memo: this.memo,
                name: this.name,
                idxCrmClient: this.idxCrmClient,
                status: this.status,
                purPayWay: this.purPayWay,
                idxCrmCompany: this.idxCrmCompany,
                purPrice: this.purPrice
            }

            if(this.btnDisabled){
                alert('저장중입니다. 잠시만 기다려주세요.');
                return false;
            }

            this.btnDisabled = true;
            this.axios.post('/api/v1/client/pur', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('등록 되었습니다.')
                        //this.$router.push('/origin/customer_acceptance_b2c')
                        history.back()
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                }).finally(()=>{
                    this.btnDisabled = false;
                })

        }
    }
}
</script>

<style lang="scss">
#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .c {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}

.point {
    font-family: gulim;
    font-size: 9pt;
    color: #FAF7E7;
}

.textbox {
    font-family: gulim;
    font-size: 9pt;
    color: #736257;
    height: 20px;
}

.msg_wrap {
    width: 230px;
}

.msg_deco_wrap {
    border-radius: 13px 13px 0 0;
    background: #efefef;
    padding: 12px 0 0 0;
}

.msg_deco {
    width: 40px;
    height: 2px;
    background: #ababab;
    border-radius: 20px;
    margin: 0 auto;
}

.msg_box {
    background: #efefef;
    padding: 8px 20px 10px 20px;
    display: block;
    height: 200px;
}

.msg_box textarea {
    width: 100%;
    height: 100%
}

.msg_send {
    background: #efefef;
    padding: 0 20px 20px 20px;
}

.msg_footer {
    background: #efefef;
    border-radius: 0 0 13px 13px;
    padding: 0 20px 20px 20px;
    text-align: center;
}
</style>
